.text-gpt-wrapper {
  background: #fff;
  padding: 32px 0px 32px 32px;
  position: relative;
  overflow: hidden;
  .chat-wrapper {
    width: 100%;
    // max-width: 1300px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 158px);
    .gpt-type{
      display: flex;
      margin-left: 13%;
      align-items: center;
    }
    .chat-main {
      flex: 1;
      margin: 12px 0px;
      padding-top: 20px;
      text-align: center;
      label {
        color: #323130;
        font-size: 36px;
        text-align: center;
        display: block;
      }
      p {
        color: #323130;
        font-size: 18px;
        text-align: center;
      }
      img {
        width: 100vh;
      }
    }
    .chat-info {
      flex: 1;
      margin: 12px 0px;
      overflow: auto;
      padding: 10px;
    }
    .chat-input {
      margin: auto;
      position: relative;
      width: 60%;
      .site-form-item-icon {
        font-size: 20px;
        position: absolute;
        color: #c1c1c1;
        padding: 2px;
        bottom: 2px;
        left: 8px;
        z-index: 10;
      }
      .upload-item-icon {
        font-size: 20px;
        position: absolute;
        border-radius: 5px;
        color: #c1c1c1;
        padding: 2px;
        bottom: 2px;
        left: 8px;
        z-index: 10;
        cursor: pointer;
      }
      .websearch-item-icon {
        font-size: 20px;
        position: absolute;
        border-radius: 5px;
        padding: 2px;
        bottom: 2px;
        left: 34px;
        z-index: 10;
        cursor: pointer;
      }
      .upload-item-icon:hover, .websearch-item-icon:hover {
        background-color: #f1f1f1;
      }
      .site-form-item-btn {
        position: absolute;
        bottom: 4px;
        right: 10px;
        z-index: 10;
      }
      #dialogueInput {
        padding: 4px 18px 0 10px;
      }
      #dialogueInput::-webkit-scrollbar {
        width: 5px;
        background-color: #f1f1f1;
      }

      #dialogueInput::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
      }
    }
  }
  .record-wrapper {
    position: absolute;
    top: 32px;
    right: 50px;
    border-radius: 6px;
    box-shadow: 0px 3px 5px 1px rgba(0, 0, 0, 0.16);
  }
  .ant-drawer-body {
    padding: 24px 0px !important;
  }
  .drawer-header {
    margin-bottom: 20px;
    padding: 0px 24px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .drawer-content {
    height: calc(100vh - 220px);
    overflow: auto;
    border-top: 1px solid #c0c4cc;
    padding: 0px 24px;
  }
  .drawei-content-create {
    width: 100%;
    margin-top: 20px;
    height: 34px;
    background-color: #0078d4;
  }
  .drawer-content-date {
    margin-bottom: 20px;
  }
  .drawer-content-title {
    color: #c0c4cc;
  }
  .drawer-content-input {
    border: 1px solid #c0c4cc;
    padding: 4px 11px;
    margin-bottom: 20px;
    height: 34px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  .drawer-content-input-select {
    background: #f5f7fa;
  }
  .drawer-content-input:hover {
    background: #f5f7fa;
  }
  .drawer-content-input > span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 10px;
  }
  .summaryContent {
    width: 100%;
  }
  .drawer-footer {
    text-align: center;
    color: #c0c4cc;
  }
  .drawer-icon-right {
    color: #c0c4cc;
  }
  .drawer-content-icon-btn {
    cursor: pointer;
    color: #323130;
  }
  .drawer-content-icon-btn:hover {
    cursor: pointer;
    color: #0078d4;
  }
  .editSpan {
    margin-left: 30px;
    margin-right: 10px;
    cursor: pointer;
  }
  .editSpan:hover {
    color: #0078d4;
  }
  .editSwitch {
    display: inline-block;
  }
  .ant-upload-list-item {
    float: left;
    width: 200px;
    margin-inline-end: 8px;
  }
  
  .ant-upload-rtl.upload-list-inline .ant-upload-list-item {
    float: right;
  }

  .historyEditInput {
    width: 100%;
    height: 80%;
    border-radius: 5px;
    border: 1px solid lightgray;
    margin-right: 10px;
  }

  .historyEditInput:focus {
    outline: none;
  }

  .historyEditIcon {
    font-size: 15px;
  }

  .summaryLoading {
    margin: auto;
  }
}