.ms-MessageBar-innerText,.ms-MessageBar-dismissSingleLine,.ms-MessageBar-icon {
    display: flex;
    align-items: center;
}
/* #messageDomWrapper {
    animation: show .5s ease-in forwards;
}
@keyframes show {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
} */