  .qa-table-row .ant-table-thead .ant-table-cell{
    text-align: center;
    font-weight: 400;
  }

  .list-question-label {
    margin-right: 1em;
    margin-bottom: 1em;
  }

  .list-question-ul {
    margin: 0;
    padding: 0;
    list-style: inside;
  }
  
  .list-question-li {
    margin-bottom: 0.5em;
  }

  .list-question-li-more {
    color: #0078D4;
    cursor: pointer;
  }

  .list-status-always {
    display: inline-block;
    color: #67C23A;
    width: 42px;
    height: 20px;
    font-size: 10px;
    line-height: 20px;
    background: #F0F9EB;
    border-radius: 2px;
    border: 1px solid #67C23A;
    text-align: center;
  }

  .list-status-isActive {
    display: inline-block;
    color: #FFFFFF;
    width: 42px;
    height: 20px;
    font-size: 10px;
    line-height: 20px;
    background: #67C23A;
    border-radius: 2px;
    border: 1px solid #67C23A;
    text-align: center;
  }

  .list-status-notActive {
    display: inline-block;
    color: #FFC261;
    width: 42px;
    height: 20px;
    font-size: 10px;
    line-height: 20px;
    background: #FEF7ED;
    border-radius: 2px;
    border: 1px solid #FFC261;
    text-align: center;
  }

  .list-answer:not(:last-child) {
    border-bottom: 1px solid #ccc;
  }

  .list-answer-wrapper {
    margin-right: 10px;
    word-break: break-all;
  }

  .list-answer-image-wrapper {
    display: flex;
  }

  .list-answer-span:not(:first-child) {
    margin-left: 5px;
  }

  .list-answer-img {
    max-width: 50px;
    max-height: 50px;
    object-fit: contain;
    cursor: pointer;
    margin-right: 5px;
  }

  .dialog-footer {
    display: flex;
    justify-content: flex-end;
  }

  /* detail */
  .ant-tabs-nav-add {
    height: 40px;
  }

  .detail-tip-h2 {
    font-size: 14px;
    font-weight: 400;
    color: #303133;
    line-height: 19px;
    padding-left: 10px;
  }

  .detail-tip-ul {
    margin: 10px;
    padding: 0px;
    display: flex;
    flex-direction: column;
  }

  .detail-tip-li-right {
    background: #409EFF;
    border-radius: 16px;
    color: #fff;
    text-align: right;
    align-self: flex-end;
    margin-bottom: 1em;
    padding: 10px;
  }

  .detail-tip-ul p {
    font-size: 12px;
    margin: 0;
  }

  .detail-tip-li-left {
    background: #F0F2F7;
    border-radius: 16px;
    margin-bottom: 1em;
    padding: 10px;
  }

  .detail-tip-p-question {
    margin-top: 10px!important;
    color: #409EFF;
  }