.smartkg-wrapper {
  background: #fff;
  height: calc(100vh - 202px);
  padding: 108px 40px 0px 70px;
  display: flex;
  img {
    width: 305px;
    object-fit: contain;
    align-self: flex-start;
  }
  .smartkg-content {
    margin-left: 88px;
    h1 {
      font-size: 36px;
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: 400;
      color: #323130;
      margin-top: 0px;
    }
    h3 {
      font-size: 18px;
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: 400;
      color: #323130;
      line-height: 32px;
    }
  }
}

.searchDisplay {
  width: 100%;
  height: 100%;
  .chart-page {
    width: 100%;
    height: 100%;
    position: relative;
    .echarts-wrapper {
      width: 100%;
      height: 100%;
    }
    .chart-wrap {
      position: absolute;
      top: 20px;
      left: 20px;
      .chart-select-wrap {
        margin-bottom: 20px;
      }
      .chart-search-wrap {
        width: 472px;
        .search-result-wrap {
          width: 100%;
          margin-top: 20px;
          .search-result-info {
            color: #c9c9c9;
            margin-bottom: 10px;
            display: block;
          }
          .search-result-lists {
            max-height: 30vh;
            overflow: auto;
            background: #f5f5f5;
            padding: 1em;
            .search-result {
              cursor: pointer;
              margin: 0px;
              height: 32px;
              line-height: 32px;
              padding: 0px 10px;
            }
            .search-result:hover,
            .active {
              background: #eeeeee;
            }
          }
        }
      }
    }
  }
}

.bot-wrap {
  .chat-wrap {
    width: 400px;
    height: 523px;
    display: flex;
    flex-direction: column;
    .chat-title {
      background: #0084fb;
      height: 35px;
      padding: 5px 15px;
      line-height: 35px;
      color: #fff;
      font-size: 14px;
    }
    .history {
      width: 100%;
      overflow: auto;
      box-sizing: border-box;
      padding: 5px;
      flex: 1;
      background-color: #fafafa;
    }
    .send {
      padding: 5px;
      background-color: #fafafa;
    }
  }
}
.ant-popconfirm .ant-popover-inner{
  padding: 12px !important;
  z-index: 1080!important;
}
.ant-popover-placement-topRight .ant-popover-inner {
  padding: 0px !important;
  border-radius: 0px;
  background-color: unset;
  .history {
    .chat-user {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 10px;
      .chat-user-input {
        max-width: 60%;
        color: #fff;
        background-color: #0078d4;
        border-radius: 5px;
        padding: 7px 10px;
        font-size: 14px;
        margin: 0px;
      }
      .chat-user-icon {
        width: 28px;
        height: 28px;
        text-align: center;
        line-height: 28px;
        border-radius: 50%;
        background: #9e9e9e;
        color: #fff;
        margin: 2px 0px 0px 10px;
      }
    }
    .chat-bot {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 10px;
      .chat-bot-input {
        max-width: 60%;
        color: #323130;
        background-color: #f3f3f3;
        border-radius: 5px;
        padding: 7px 10px;
        font-size: 14px;
        margin: 0px;
        white-space: pre-wrap;
      }
      .chat-bot-icon {
        width: 28px;
        height: 28px;
        text-align: center;
        line-height: 28px;
        border-radius: 5px;
        background: #0078d4;
        color: #fff;
        margin: 2px 10px 0px 0px;
      }
    }
  }
}
.ant-modal-content {
  border-radius: 0 !important;
}