.loginWrapper {
    display: flex;
}

.loginLeftPane {
    display: flex;
    min-width: 720px;
    width: 50%;
    min-height: 800px;
    height: 100vh;
    align-items: flex-end;
}

.loginRightPane {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    min-width: 600px;
    /* padding: 32px 64px; */
}

.loginTitle {
    margin-bottom: 100px;
}

.forgetPassword:focus-visible {
        outline: 2px solid #91caff !important;
        outline-offset: -2px !important;
}