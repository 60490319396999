body {
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    margin: 0!important;
}

/* app */
.root {
    width: 100%;
    height: 100%;
    overflow-x: overlay;
}

.App {
    min-width: 1366px;
}

/* layout */
.body-wrapper {
    width: 100%;
    height: calc(100vh - 48px);
    overflow: auto;
    display: flex;
}

.content-wrapper {
    width: calc(100% - 228px);
    overflow: auto;
}

.container-wrapper {
    background: #F5F5F5;
    height: calc(100vh - 94px);
    overflow: auto;
}

/* header */
.PersonaWrapper {
    width: 180px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-evenly;
    height: 100%;
    cursor: pointer;
    user-select: none;
}

.PersonaWrapper:hover {
    background-color: #106ebe;
}

.PersonaWrapper:active {
    background-color: #1664a7;
}