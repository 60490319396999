.record-wrapper {
  position: absolute;
  top: 32px;
  right: 50px;
  border-radius: 6px;
  box-shadow: 0px 3px 5px 1px rgba(0, 0, 0, 0.16);
}
.ant-drawer-body {
  padding: 24px 0px !important;
}
.drawer-header {
  margin-bottom: 20px;
  padding: 0px 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.drawer-content {
  height: calc(100vh - 220px);
  overflow: auto;
  border-top: 1px solid #c0c4cc;
  padding: 0px 24px;
}
.drawei-content-create {
  width: 100%;
  margin-top: 20px;
  height: 34px;
  background-color: #0078d4;
}
.drawer-content-date {
  margin-bottom: 20px;
}
.drawer-content-title {
  color: #c0c4cc;
}
.drawer-content-input {
  border: 1px solid #c0c4cc;
  padding: 4px 11px;
  margin-bottom: 20px;
  height: 34px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.drawer-content-input-select {
  background: #f5f7fa;
}
.drawer-content-input:hover {
  background: #f5f7fa;
}
.drawer-content-input > span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 10px;
}
.drawer-footer {
  text-align: center;
  color: #c0c4cc;
}
.drawer-icon-right {
  color: #c0c4cc;
  font-size: 16px;
}
.drawer-header-text {
  margin-left: 30px;
  font-size: 14px;
  color: #c0c4cc;
}
.drawer-content-icon-btn {
  cursor: pointer;
  color: #323130;
}
.drawer-content-icon-btn:hover {
  cursor: pointer;
  color: #0078d4;
}