  .scenarioSettingWrapper {
    background: #fff;
    height: calc(100vh - 94px);
    overflow: auto;
  }
  
  .scenarioSettingHeader {
    font-size: 20px;
    font-weight: 400;
    width: 1122px;
    height: 56px;
    line-height: 56px;
    margin-left: 32px;
  }