  .detail-answer-content {
    background: #E8F4FF;
    padding: 10px;
    flex: 1;
    display: flex;
    justify-content: center;
  }

  .detail-answer-wrapper {
    width: 319px;
  }

  .detail-img-uploader .ant-upload{
    background: #fff;
  }

  .detail-img-uploader .ant-upload.ant-upload-select{
    width: 100% !important;
    margin-right: 0px !important;
  }

  .detail-answer-upload-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
