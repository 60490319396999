.realtime-wrapper {
    background: #fff;
    display: flex;
    height: 100%;
    position: relative;
    overflow: hidden;
}
.left-wrapper {
    width: 50%;
    height: 100%;
    padding: 20px 40px;
}
.right-wrapper {
    width: 50%;
    height: 100%;
}
.user-wrapper {
    margin: 20px;
}
.wrapper-title {
    font-weight: bold;
}
.audio-wrapper {
    height: 90%;
    margin-top: 10px;
    border-radius: 10px;
    background-color: rgb(243, 242, 241);
}
.history-wrapper {
    padding-top: 20px;
    height: 70%;
    overflow: auto;
    flex-direction: column;
}
.session-wrapper {
    line-height: 30px;
    font-size: 14px;
}
.mic-wrapper {
    height: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.mic-button {
    border-radius: 100%;
    height: 50px;
    width: 50px;
    margin-bottom: 10px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
}
.mic-button:hover {
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}
.mic-icon {
    font-size: 20px;
    color:#0078d4;
}
.mic-word {
    font-size: 10px;
}
.audio-user {
    display: flex;
    margin-bottom: 5px;
    justify-content: flex-end;
}
.audio-user-icon {
    width: 28px;
    height: 28px;
    text-align: center;
    line-height: 28px;
    border-radius: 50%;
    background: #9e9e9e;
    color: #fff;
    margin: 0 10px;
}
.audio-user-input {
    max-width: 80%;
    color: #fff;
    white-space: pre-wrap;
    background-color: #0078d4;
    border-radius: 5px;
    padding: 7px 10px;
    font-size: 14px;
    margin: 0px;
}
.audio-bot {
    display: flex;
    margin-bottom: 5px;
    justify-content: flex-start;
}
.audio-bot-icon {
    width: 28px;
    height: 28px;
    text-align: center;
    line-height: 28px;
    border-radius: 5px;
    background: #0078d4;
    color: #fff;
    margin: 0 10px;
}
.audio-bot-input {
    width: 80%;
    color: #323130;
    background-color: #dbdbe1;
    border-radius: 5px;
    padding: 7px 10px;
    font-size: 14px;
    margin: 0px;
}
.model-options {
    margin-top: 10px;
}
.voice-options {
    margin-top: 15px;
}
.custom-assistant-wrapper {
    margin-top: 15px;
}
.custom-assistant-input {
    margin-top: 15px;
}
.custom-assistant-button {
    margin-top: 15px;
}
.custom-modal .ant-modal-content, .custom-modal .ant-modal-header, .custom-modal .ant-modal-footer {
    border-radius: 10px !important;
}