.image-gpt-wrapper {
  background: #fff;
  padding: 32px 0px 32px 32px;
  position: relative;
  overflow: hidden;
  .chat-wrapper {
    width: 100%;
    // max-width: 1078px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 158px);
    .gpt-type{
      margin-left: 13%;
    }
    .chat-main {
      flex: 1;
      margin: 12px 0px;
      padding-top: 20px;
      text-align: center;
      label {
        color: #323130;
        font-size: 36px;
        text-align: center;
        display: block;
      }
      p {
        color: #323130;
        font-size: 18px;
        text-align: center;
      }
      img {
        width: 100vh;
      }
    }
    .chat-info {
      flex: 1;
      margin: 12px 0px;
      overflow: auto;
      padding: 10px;
    }
    .chat-input {
      margin: auto;
      position: relative;
      width: 60%;
      .site-form-item-icon {
        font-size: 20px;
        position: absolute;
        bottom: 4px;
        left: 5px;
        z-index: 10;
      }
      .site-form-item-btn {
        position: absolute;
        bottom: 4px;
        right: 10px;
        z-index: 10;
      }
      #dialogueInput {
        padding: 4px 30px 4px 30px;
      }
      #dialogueInput::-webkit-scrollbar {
        width: 5px;
        background-color: #f1f1f1;
      }

      #dialogueInput::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
      }
    }
  }
  .record-wrapper {
    position: absolute;
    top: 32px;
    right: 50px;
    border-radius: 6px;
    box-shadow: 0px 3px 5px 1px rgba(0, 0, 0, 0.16);
  }
  .chat-user {
    display: flex;
    justify-content: flex-end;
    margin: 0px 17% 20px 0px;
  }
  .chat-user-input {
    max-width: 60%;
    color: #fff;
    background-color: #0078d4;
    border-radius: 5px;
    padding: 7px 10px;
    font-size: 14px;
    margin: 0px;
    word-break: break-word;
  }
  .chat-user-icon {
    width: 28px;
    height: 28px;
    text-align: center;
    line-height: 28px;
    border-radius: 50%;
    background: #9e9e9e;
    color: #fff;
    margin: 2px 0px 0px 10px;
  }
  .chat-bot {
    display: flex;
    justify-content: flex-start;
    margin: 0px 0px 20px 17%;
  }
  .chat-bot-input {
    max-width: 60%;
    color: #323130;
    background-color: #f3f3f3;
    border-radius: 5px;
    padding: 7px 10px;
    font-size: 14px;
    margin: 0px;
  }
  .chat-bot-icon {
    width: 28px;
    height: 28px;
    text-align: center;
    line-height: 28px;
    border-radius: 5px;
    background: #0078d4;
    color: #fff;
    margin: 2px 10px 0px 0px;
  }

  .chat-bot-input-img {
    max-width: 60%;
    color: #323130;
    font-size: 14px;
  }
  .chat-bot-input-img-loading {
    margin-top: 5px;
  }
  .chat-bot-input-img-loading > span {
    margin-left: 5px;
    color: #0078d4;
  }
  .chat-bot-input-img-succeed > p {
    margin-top: 5px;
  }
  .chat-bot-img {
    max-width: 80%;
    min-width: 150px;
    max-height: 600px;
    border: 1px solid #707070;
    border-radius: 5px;
    object-fit: contain;
  }
  .chat-bot-img-btn {
    width: 150px;
    display: flex;
    justify-content: space-between;
    color: #b7b7b7;
  }

  .chat-bot-img-btn > button {
    color: #b7b7b7;
  }

  /* 弹出框 */
  .ant-drawer-body {
    padding: 24px 0px !important;
  }
  .drawer-header {
    margin-bottom: 20px;
    padding: 0px 24px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .drawer-content {
    height: calc(100vh - 220px);
    overflow: auto;
    border-top: 1px solid #c0c4cc;
    padding: 0px 24px;
  }
  .drawei-content-create {
    width: 100%;
    margin-top: 20px;
    height: 34px;
    background-color: #0078d4;
  }
  .drawer-content-date {
    margin-bottom: 20px;
  }
  .drawer-content-title {
    color: #c0c4cc;
  }
  .drawer-content-input {
    border: 1px solid #c0c4cc;
    padding: 4px 11px;
    margin-bottom: 20px;
    height: 34px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  .drawer-content-input-select {
    background: #f5f7fa;
  }
  .drawer-content-input:hover {
    background: #f5f7fa;
  }
  .drawer-content-input > span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 10px;
  }
  .drawer-footer {
    text-align: center;
    color: #c0c4cc;
  }
  .drawer-icon-right {
    color: #c0c4cc;
    font-size: 16px;
  }
  .drawer-content-icon-btn {
    cursor: pointer;
    color: #323130;
  }
  .drawer-content-icon-btn:hover {
    cursor: pointer;
    color: #0078d4;
  }
}
.setting-label {
  display: inline-flex;
  height: 30px;
  align-items: center;
  line-height: 30px;
  margin-left: 30px;
}
.setting-icon {
  font-size: 20px;
}
.setting-label:hover {
  cursor: pointer;
  color: #0078d4;
}
.select-wrapper {
  margin-left: 5px;
  margin-bottom: 10px;
}
