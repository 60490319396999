.avatar-icon-uploader, .avatar-profile-uploader {
  width: auto !important;
}
.avatar-icon-uploader .ant-upload {
  width: 200px !important;
  height: 300px !important;
}

.circle-upload-img {
  border-radius: 50%;
}

.ant-tabs-content-holder {
  overflow: auto;
}

.modal-config-number {
  border-radius: 0;
}