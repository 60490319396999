.home-wrapper {
  background: #fff;
  height: 100%;
  .home-content {
    padding: 30px 40px 50px 70px;
    height: calc(100% - 80px);
    position: relative;
    z-index: 10;
    h1 {
      font-size: 36px;
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: 400;
      color: #323130;
    }
    h3 {
      font-size: 18px;
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: 400;
      color: #323130;
      line-height: 32px;
    }
  }
  .background-img {
    position: fixed;
    bottom: 50px;
    right: 70px;
    width: 40%;
    opacity: 0.5;
    z-index: 1;
    img {
      width: 100%;
    }
  }
}