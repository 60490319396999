.mlstudio-wrapper {
  background: #fff;
  height: 100%;
  .mlstudio-content {
    padding: 30px 40px 0px 70px;
    height: calc(100% - 30px);
    background: url("SRC/assets/imgs/mlstudio_background.png") no-repeat bottom center;
    background-size: contain;
    h1 {
      font-size: 36px;
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: 400;
      color: #323130;
    }
    h3, p {
      font-size: 18px;
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: 400;
      color: #323130;
      line-height: 32px;
    }
  }
}