.cardImageContainer {
    position: relative;
  }

.cardImageContainer:hover .cardImage {
    opacity: 0.2;
  }
  
  .cardImageContainer:hover .cardImageFunctionBar {
    opacity: 1;
  }
  
