.chat-user {
    display: flex;
    justify-content: flex-end;
    margin: 0px 17% 20px 0px;
}

.chat-user-input {
    max-width: 65%;
    color: #fff;
    white-space: pre-wrap;
    background-color: #0078d4;
    border-radius: 5px;
    padding: 7px 10px;
    font-size: 14px;
    margin: 0px;
}

.chat-user-edit-icon {
    margin-right: 10px;
    line-height: 33px;
    display: none;
}

.chat-user:hover .chat-user-edit-icon {
    display: inline-block;
}

.chat-user-edit-icon:hover {
    color: lightblue;
    cursor: pointer;
}

.chat-user-input-wrap {
    display: flex;
    width: 70%;
    flex-direction: column;
    border-radius: 5px;
    background-color: lightgray;
    padding: 20px;
}

.chat-user-input-wrap div {
    display: flex;
    height: 30px;
    justify-content: flex-end;
}

.chat-user-edit-button {
    margin: 5px;
}

.chat-user-image {
    max-width: 500px;
    max-height: 500px;
    width: auto;
    height: auto;
    object-fit: contain;
}

.chat-user-icon {
    width: 28px;
    height: 28px;
    text-align: center;
    line-height: 28px;
    border-radius: 50%;
    background: #9e9e9e;
    color: #fff;
    margin: 2px 0px 0px 10px;
}

.chat-bot {
    display: flex;
    justify-content: flex-start;
    margin: 0px 0px 10px 17%;
}

.chat-bot-copy {
    visibility: hidden;
    margin: 0px 0px 10px 20%;
}

.chat-bot-wrapper:hover .chat-bot-copy {
    visibility: visible;
}

.copy-btn {
    border: none;
}

.chat-bot-input {
    width: 65%;
    color: #323130;
    background-color: #f3f3f3;
    border-radius: 5px;
    padding: 7px 10px;
    font-size: 14px;
    margin: 0px;
}

.chat-bot-icon {
    width: 28px;
    height: 28px;
    text-align: center;
    line-height: 28px;
    border-radius: 5px;
    background: #0078d4;
    color: #fff;
    margin: 2px 10px 0px 0px;
}