.scenarioConfiguratePanelWrapper {
  padding: 5px;
  display: flex;
  justify-content: center;
  background: #e8f4ff;
  border-radius: 2px 2px 2px 2px;
  opacity: 1;
  align-items: center;
  margin-bottom: 40px;
}
.scenarioConfigurateDivider {
  height: 1px;
  background: #edebe9;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}
